import React, { useState, useEffect } from 'react';


import Helmet from '../components/Helmet/Helmet';
import '../styles/home.css';
import '../styles/shop.css';


import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import ProductsList from '../components/UI/ProductsList';

import useGetsData from '../custom-hooks/useGetsData';

import b1 from '../assets/images/banner/b1.jpg'
import b2 from '../assets/images/banner/b2.jpg'
import b3 from '../assets/images/banner/reseau2.jpg'
import ProductsListgros from '../components/UI/ProductsListgros';

const Home = (item) => {



  const { data: products, loading } = useGetsData('products')



  const [chemiseProducts, setChemiseProducts] = useState([]);
  const [electroProducts, setElectroProducts] = useState([]);
  const [arriveProducts, setArrivageProducts] = useState([]);
  const [sacsProducts, setSacProducts] = useState([]);
  const [panshortProducts, setPanshortProducts] = useState([]);
  const [pullshirtProducts, setPullshirtProducts] = useState([]);
  const [tapchauProducts, setTapchauProducts] = useState([]);
  const [grosProducts, setGrosProducts] = useState([]);


  const year = new Date().getFullYear();
  useEffect(() => {
    const filtrearrivageProducts = products.filter(
      (item) => item.arrivage
    );

    const filtregrosProducts = products.filter(
      (item) => item.gros
    );

    const filtrechemiseProducts = products.filter(
      item => item.category === "chemise" || item.category === "t-shirt"
    );

    const filtrepullAndTshirtProducts = products.filter(
      item => item.category === "pulls" || item.category === "ensemble"
    );

    const filtretapProducts = products.filter(
      item => item.category === "tapete" || item.category === "chaussure"
    );

    const filtrepanAndshortProducts = products.filter(
      item => item.category === "pantalon" || item.category === "short"
    );

    const filtresacsAndCasquetteProducts = products.filter(
      item => item.category === "sacs" || item.category === "casquette"
    );

    const filtreelectroProducts = products.filter(
      (item) => item.category === "electronique"
    );


    const produitarrivage = filtrearrivageProducts.slice(0, 4)
    const produitchemise = filtrechemiseProducts.slice(0, 4)
    const produitpullshirt = filtrepullAndTshirtProducts.slice(0, 4)
    const produitpanshort = filtrepanAndshortProducts.slice(0, 4)
    const produitsac = filtresacsAndCasquetteProducts.slice(0, 4)
    const produittapet = filtretapProducts.slice(0, 4)
    const produitelectro = filtreelectroProducts.slice(0, 4)
    const produitgros = filtregrosProducts.slice(0, 4)


    setTapchauProducts(produittapet)
    setArrivageProducts(produitarrivage);
    setChemiseProducts(produitchemise);
    setPullshirtProducts(produitpullshirt);
    setPanshortProducts(produitpanshort);
    setSacProducts(produitsac);
    setGrosProducts(produitgros);
    setElectroProducts(produitelectro)

  }, [products]);



  const handleLink = () => {
    // Recharge la page lorsque le lien est cliqué
    window.location.href = '/produitgros';
  };



  return <Helmet title={"Home"} >

    <section className="hero__section ">


      <div className="col-lg-12">
        <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={b1} class="d-block w-200" alt="..." />
              <div class="carousel-caption  d-flex flex-column ">
                <h6 className='text-white'>Bienvenue sur Zombuy </h6>
                <motion.button whileTap={{ scale: 1.2 }}
                  className="buy__btn0 "><Link to='/shop' >Acheter </Link>
                </motion.button>
              </div>
            </div>
            <div class="carousel-item">
              <img src={b2} class="d-block w-200" alt="..." />
              <div class="carousel-caption  d-flex flex-column">
                <h6 className='text-white' >Lançer votre site web dans 10 jours </h6>
                <motion.button whileTap={{ scale: 1.2 }}
                  className="buy__btn1 "><Link to='/service' >Commande </Link>
                </motion.button>
              </div>
            </div>
            <div class="carousel-item">
              <img src={b3} class="d-block w-200" alt="..." />
              <div class="carousel-caption  d-flex flex-column ">
                <h6 className='text-white' >A partir de 75000 Fcfa </h6>
                <motion.button whileTap={{ scale: 1.2 }}
                  className="buy__btn2 "><Link to='/service' >Commande </Link>
                </motion.button>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>



    </section>


    <div className='shop_data'>


        <section className="shop-products p-5">
          <div className="container-xxl">
            <div className="row">
              <Col lg='12' className='text-center mb-4'>
                <h2 className='section__title'>Nouvelles Arrivages</h2>
              </Col>
              {
              loading ? (<h5 className='fw-bold'>Loading.....</h5>) : (
              <ProductsList data={arriveProducts} />
              )}
            </div>
          </div>
        </section>
      

      {chemiseProducts.length > 0 && (
        <section className="shop-products p-5">
          <div className="container-xxl">
            <div className="row">
              <Col lg='12' className='text-center mb-4'>
                <h2 className='section__title'>Chemises et T-shirt</h2>
              </Col>
              <ProductsList data={chemiseProducts} />
            </div>
          </div>
        </section>
      )}

      {grosProducts.length > 0 && (
        <section className="shop-products p-5">
          <div className="container-xxl">
            <div className="row">
              <Col lg='12' className='text-center mb-2'>
                <h2 className='section__title'>Vente en Gros</h2>
              </Col>
              <div className='gros'>
                <Link onClick={handleLink} className='mb-2 grostext' >Voir Plus </Link>
              </div>
              {
                loading ? (<h5 className='fw-bold'>Loading.....</h5>) : (
                  <ProductsListgros data={grosProducts} />
                )}
            </div>
          </div>
        </section>
      )}

      {pullshirtProducts.length > 0 && (
        <section className="shop-products p-5">
          <div className="">
            <div className="row">
              <Col lg='12' className='text-center mb-4'>
                <h2 className='section__title'>Pull et Ensemble</h2>
              </Col>

              <ProductsList data={pullshirtProducts} />

            </div>
          </div>
        </section>
      )}

      {panshortProducts.length > 0 && (
        <section className="shop-products p-5" >
          <div className="container-xxl">
            <div className="row">
              <Col lg='12' className='text-center mb-4'>
                <h2 className='section__title'>Pantalon et Short</h2>
              </Col>

              <ProductsList data={panshortProducts} />

            </div>
          </div>
        </section>
      )}

      {tapchauProducts.length > 0 && (
        <section className="shop-products p-5">
          <div className="container-xxl">
            <div className="row">
              <Col lg='12' className='text-center mb-4'>
                <h2 className='section__title'>Chaussures et Tapetes</h2>
              </Col>

              <ProductsList data={tapchauProducts} />

            </div>
          </div>
        </section>
      )}

      {sacsProducts.length > 0 && (
        <section className=" shop-products p-5">
          <div className="container-xxl">
            <div className="row">
              <Col lg='12' className='text-center mb-4'>
                <h2 className='section__title'>Sacs et Casquettes</h2>
              </Col>

              <ProductsList data={sacsProducts} />

            </div>
          </div>
        </section>
      )}


      {electroProducts.length > 0 && (
        <section className=" shop-products p-5">
          <div className="container-xxl">
            <div className="row">
              <Col lg='12' className='text-center mb-4'>
                <h2 className='section__title'>Electroniques </h2>
              </Col>

              <ProductsList data={electroProducts} />

            </div>
          </div>
        </section>
      )}


    </div>


  </Helmet>;
};

export default Home;
