import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import '../styles/dashboard.css'

const Listemessage = () => {
  const { data: products, loading } = useGetsData('Message');
  const [productsData, setProductsData] = useState(products);
  const navigate = useNavigate();

  useEffect(() => {
    setProductsData(products);
  }, [products]);


  useEffect(() => {
    const sortedProducts = [...products].sort((a, b) => b.Date - a.Date);
    setProductsData(sortedProducts);
  }, [products]);


  const deleteProduct = async (id) => {
    await deleteDoc(doc(db, 'Message', id))
    toast.success('Suppression effectuée !')
  }

  return (
    <>

      <Container>

        <Row>
          <Col lg='12'>
            <div className="table-responsive mb-5 mt-5">
              <table className='table bordered'>
                <thead>
                  <tr>
                    <th style={{ width: 100 }}>Nom</th>
                    <th style={{ width: 100 }}>Email </th>
                    <th style={{ width: 60 }}>Téléphone</th>
                    <th style={{ width: 150 }}>Sujet</th>
                    <th style={{ width: 400 }}>Message </th>
                    <th style={{ width: 10 }}>Date </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (<h3 className='py-5 fw-bold'>loading....</h3>) : (productsData.map(item => (
                    <tr key={item.id}>
                      <td>{item.Nom} </td>
                      <td>{item.Email} </td>
                      <td>{item.phone} </td>
                      <td>{item.Sujet} </td>
                      <td>{item.Msg} </td>
                      <td>{item.Date ? item.Date.toDate().toLocaleString() : ""}
                      </td>
                      <td>
                        <button onClick={() => { deleteProduct(item.id) }} className="btn btn-danger">Delete</button>
                      </td>
                    </tr>
                  )))
                  }
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Listemessage;
