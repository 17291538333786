import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'



const firebaseConfig = {  
  apiKey: "AIzaSyD5L0_iyjJPQTTzcGRT-RYOQD5RkXpSw-8",
  authDomain: "testdata-e6b61.firebaseapp.com",
  projectId: "testdata-e6b61",
  storageBucket: "testdata-e6b61.appspot.com",
  messagingSenderId: "163812715127",
  appId: "1:163812715127:web:810e58635133f87a11f472"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)

export default app;