import React from 'react'

import "../../styles/product-card.css"


import { motion } from 'framer-motion'
import { Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import {  toast } from 'react-toastify';

import { useDispatch } from 'react-redux'
import { cartActions } from '../../redux/slices/cartSlice'







const ProductsCardgros = ({ item }) => {

  
const dispatch = useDispatch()

const handleLink = () => {
  // Recharge la page lorsque le lien est cliqué
  window.location.href = `/shopgros/${item.id}`;
};

  return (
    <Col lg='3' md='4' className="mb-3" >
    <div className="card ">
      <div className="m-2 p-2 card" >
       <Link onClick={handleLink}>
        <motion.img whileHover={{ scale: 0.9 }} src={item.imgUrl} alt="fluid"  />
      </Link>
      </div>
      <div className="card-details p-2">
        <p className='nom'>{item.productName}</p>

        <p className=" my-1"><span className="qty">{item.qty} piéces </span>  <span className="pric">{item.price} Fcfa</span>  </p>
        <div className="d-flex align-items-center justify-content-around  gap-5">
        
        
            <Link onClick={handleLink}  className='view-button '>
               Detaille
            </Link>
            
          <div className="what">
          <a href="https://wa.me/+22892170365">
            <motion.button className="add-btn "
             whileTap={{ scale: 1.2 }}
         
            >
           <i class="ri-whatsapp-fill what "></i>
                                         
            </motion.button>
            </a>
          </div>
         


       
      </div>
    </div>


  </div>
  </Col>
  )
}

export default ProductsCardgros
