import React, { useState, useEffect, useContext, createContext } from 'react'

import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';





import Helmet from '../components/Helmet/Helmet';
import CommoSection from '../components/UI/CommoSection';
import ProductsList from '../components/UI/ProductsList';


import { db } from '../firebase.config';
import { doc, getDoc } from 'firebase/firestore';
import useGetsData from '../custom-hooks/useGetsData';



const Productaffiche = () => {

  const { data: products, loading } = useGetsData('products')

  const [productsData ] = useState(products)
 

  return (
    <Helmet title='product'>
      <CommoSection title='product' />


      <section className="shop-products p-5">
     <div className="container-xxl">
       <div className="row">
         {loading.length === 0 ? (
           <h1 className='text-center fs-4'></h1>
         ) : (
           <ProductsList data={productsData} />

         )}
       </div>
     </div>
   </section>
      
   
    </Helmet>
  )
}

export default Productaffiche
