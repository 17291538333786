import React from 'react'
import { useLocation } from 'react-router-dom';
import Helmet from '../Helmet/Helmet';
import CommoSection from '../UI/CommoSection';
import ProductsList from '../UI/ProductsList';


const Search = () => {
    const location = useLocation();
  const productsData = location.state ? location.state.productsData : [];
  

  return (
    <Helmet title='Search Results'>
    <CommoSection title='Search Results' />
    <section className="shop-products p-5">
      <div className="container-xxl">
        <div className="row">
        { productsData.length === 0? (
       <h1 className='text-center fs-4'> Aucune produits  trouver !</h1>
    ) : (
        <ProductsList data={productsData} />
        
      )}
        </div>
      </div>
    </section>
  </Helmet>
  )
}

export default Search
