import React, {useState} from 'react'
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';

import { toast } from 'react-toastify';

import {db, storage} from '../firebase.config'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';


const AddService = () => {

  const [enterTitle, setEnterTitle] = useState('')
  const [enterShortDesc, setEnterSortDes] = useState('')
  const [enterProductImg, setEnterProductImg] = useState(null)
  const [loading, setLoading] = useState(false)
  const [addingProduct, setAddingProduct] = useState(false);

  const navigate = useNavigate()

  const addProduct = async(e) => {
    e.preventDefault()

    if (addingProduct) return;

    setLoading(true)
    setAddingProduct(true);


    // ===== Ajouter les product dans la bases de données =====

    try  {

      const docRef = await collection(db,'service')

      const storageRef = ref(storage, `productImg/${Date.now() + enterProductImg.name}`)
      const uploadTast =uploadBytesResumable(storageRef, enterProductImg)

      uploadTast.on(()=>{
        toast.error('image non enregistre')
      }, ()=>{
        getDownloadURL(uploadTast.snapshot.ref).then(async (downloadURL)=>{
          await addDoc(docRef, {
            nomService : enterTitle,
            Description: enterShortDesc,
            imgUrl: downloadURL,
          })
        })

       
        toast.success('Service enregistre');
        navigate('/dashboard/all-service')

      })

    }catch (error) {
      setLoading(false)
      setAddingProduct(false);
      toast.error('service non ajouter');
    }  
  }

  return <section>
    <Container>
      <Row>
        <Col lg='12'>
         {
          loading ? <h4 className='py-5'>Loading........</h4> : <>

          <Form onSubmit={addProduct}> 
          <div>
              <FormGroup className="form__group">
                <span className="mt-4" >Image du Service</span>
                <input type="file" 
                 onChange={(e)=>setEnterProductImg(e.target.files[0])} 
                required  />
              </FormGroup>
            </div>

            <FormGroup className="form__group">
              <span>Nom du Service </span>
              <input
               type="text" 
               placeholder='mon du service'
                 value={enterTitle}  
              onChange={e=>setEnterTitle(e.target.value)} 
              required />
            </FormGroup> 

            <FormGroup className="form__group">
              <span>Description </span>
              < textarea
              type="text" placeholder='description....' 
              value={enterShortDesc} 
              onChange={e=>setEnterSortDes(e.target.value)}
               required />
            </FormGroup>
          
             <button className="buy__btn mb-5 " type='submit'> Ajouter </button>
          </Form>
          </>
         }
        </Col>
      </Row>
    </Container>
  </section>
}

export default AddService
