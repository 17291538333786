import React, { useState } from 'react'
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';

import Helmet from '../components/Helmet/Helmet';
import '../styles/login.css';
import { Link, useNavigate } from 'react-router-dom';


import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase.config';
import { toast } from 'react-toastify'

const Login = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      const userCredential = await signInWithEmailAndPassword(auth, email,
        password)

      const user = userCredential.user

      console.log(user)
      setLoading(false)
      toast.success('Authentification reussit')
      navigate('/Home')

    } catch (error) {
      setLoading(false)
      toast.error(error.message);
    }
  }


  return <Helmet title="Login">
    <section>
      <Container>
        <Row>

          {
            loading ? <Col lg='12'
              className='text-center'>
              <h5 className='fw-bold'>Loading.....</h5></Col>
              :
              <Col lg='6' className='m-auto text-center'>
                <h3 className='fw-bold mb-4 mt-5'>Connexion</h3>

                <Form className="auth__form" onSubmit={login}>
                  <FormGroup className="form__group">
                    <input type="email" placeholder='Entrer votre email'
                      value={email} onChange={e => setEmail(e.target.value)} />
                  </FormGroup>

                  <FormGroup className="form__group">
                    <input type="password" placeholder='Entrer votre mot de passe'
                      value={password} onChange={e => setPassword(e.target.value)} />
                  </FormGroup>
                  <button type='submit' className="buy__b auth__btn">Se Connecter</button>
                  <p className='text-white' >Vous avez deja un Compte? {" "}
                    <Link to='/signup'>Créer un Compte</Link></p>
                </Form>

              </Col>
          }


        </Row>
      </Container>
    </section>

  </Helmet>
}

export default Login
