
import { Route, Routes, Navigate} from 'react-router-dom'


import Home from '../pages/Home'
import Shop from '../pages/Shop'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import ProductDetails from '../pages/ProductDetails'
import ProtectedRoute from './ProtectedRoute'

import AddProducts from '../admin/AddProducts'
import AllProducts from '../admin/AllProducts'
import Users from '../admin/Users'
import Service from '../pages/service'
import Produitaffiche from '../pages/produitaffiche'
import Search from '../components/Header/Search'
import AddService from '../admin/addservice'
import AllService from '../admin/AllService'
import Listemessage from '../admin/ListeMessage'
import Produitgros from '../pages/produitgros'
import ProductDetailsGros from '../pages/ProductDetailsGros'


const Routers = () => {
  return (
   
      <Routes>
        <Route path='/' element={<Navigate to='home' />} />
        <Route path='home' element={<Home />} />
        <Route path='shop' element={<Shop />} />
        <Route path='produitgros' element={<Produitgros />} />
        <Route path='produitaffiche' element={<Produitaffiche />} />
        <Route path='shop/:id' element={<ProductDetails />} />
        <Route path='shopgros/:id' element={<ProductDetailsGros />} />
        <Route path='service' element={<Service />} />

        <Route path='/*' element={<ProtectedRoute />} >

         
          <Route path='dashboard' element={<Listemessage />} />
          <Route path='dashboard/all-products' element={<AllProducts />} />
          <Route path='dashboard/add-product' element={<AddProducts />} />
          <Route path='dashboard/add-service' element={<AddService />} />
          <Route path='dashboard/all-service' element={<AllService />} />
          <Route path='dashboard/users' element={<Users />} />

        </Route>

        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='search' element={<Search />} />
      </Routes>
    


  )
}

export default Routers
