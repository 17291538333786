import React from 'react'
import ProductsCardgros from './ProductsCardgros'

const ProductsListgros = ({data}) => {
  return (
    <>
    {
      data?.map((item, index)=>(
        <ProductsCardgros item={item} key={index} />
      ))
    }
     
    </>
  )
}

export default ProductsListgros
