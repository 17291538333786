import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { db } from '../firebase.config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommoSection from '../components/UI/CommoSection';
import Helmet from '../components/Helmet/Helmet';
import useGetsData from '../custom-hooks/useGetsData';
import '../styles/home.css';
import '../styles/shop.css';

const Service = () => {

  const { data: products, loading: productsLoading } = useGetsData('service');
  const [productsData, setProductsData] = useState([]);

  const [enternom, setEnterNom] = useState('');
  const [enteremail, setEnterEmail] = useState('');
  const [entertel, setEnterTel] = useState('');
  const [entersujet, setEnterSujet] = useState('');
  const [entermsg, setEnterMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setProductsData(products);
  }, [products]);

  const addService = async (e) => {
    e.preventDefault();
   
    try {
      const docRef = await collection(db, 'Message');
      await addDoc(docRef, {
        Nom: enternom,
        Email: enteremail,
        phone: entertel,
        Sujet: entersujet,
        Msg: entermsg,
        Date: serverTimestamp() // Ajout de la date actuelle
      });
     
      // Réinitialiser les valeurs après l'ajout
      setEnterNom('');
      setEnterEmail('');
      setEnterTel('');
      setEnterSujet('');
      setEnterMsg('');
      // Naviguer vers une autre page ou afficher une notification
      toast.success('Message enregistré');
    } catch (error) {
      
      console.error('Erreur lors de l\'envoi du service :', error);
      // Afficher une notification ou gérer l'erreur
    }
  };

  return (
    <Helmet title="Service">

      <section>
        <Col lg='12' className='text-center mb-4 mt-5'>
          <h2 className='section__title serv'>Listes des Services</h2>
        </Col>
        {
        productsLoading ? (
          <div className='load'> 
          <h3 className='fw-bold '>Loading....</h3>
          </div>
        ) : (
          productsData.map((item, index) => (
            <Container className=' card mb-5' key={index}>
              <div className='text-center mb-4 mt-2'>
                <h4 className='nomser'>{item.nomService}</h4>
              </div>
              <div className=' serimgdes'>
                <div className='serimg'>
                  <div>
                    {' '}
                    <img src={item.imgUrl} alt='' className='imgservi mb-2 ' />{' '}
                  </div>
                </div>
                <div className='serdes'>
                  <p>{item.Description}</p>
                </div>
              </div>
            </Container>
          ))
        )}
      </section>

      <section>
        <Container>
          <Row>
            <Col lg='8'>
              <h4 className='mb-5 mt-5'>Enregistrez votre message</h4>
              <Form onSubmit={addService}>
                <div className='d-flex align-items-centerb justify-content-between gap-5 '>
                  <FormGroup className='form__group  w-50'>
                    <input
                      type='text'
                      placeholder='Votre nom'
                      value={enternom}
                      onChange={(e) => setEnterNom(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup className='form__group  w-50'>
                    <input
                      type='email'
                      placeholder='Votre email'
                      value={enteremail}
                      onChange={(e) => setEnterEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                </div>
                <div className='d-flex align-items-centerb justify-content-between gap-5 '>
                  <FormGroup className='form__group  w-50'>
                    <input
                      type='text'
                      placeholder='Objet'
                      value={entersujet}
                      onChange={(e) => setEnterSujet(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup className='form__group w-50'>
                    <input
                      type='numero'
                      placeholder='Votre numéro de téléphone'
                      value={entertel}
                      onChange={(e) => setEnterTel(e.target.value)}
                      required
                    />
                  </FormGroup>
                </div>
                <FormGroup className='form__group'>
                  <textarea
                    type='text'
                    placeholder='Message'
                    value={entermsg}
                    onChange={(e) => setEnterMsg(e.target.value)}
                    required
                  />
                </FormGroup>
                <button className='buy__btn' type='submit'>
                  Enregistrer
                </button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    
      </Helmet>

  );
};

export default Service;
