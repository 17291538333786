 import React, { useState } from 'react'
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';

import Helmet from '../components/Helmet/Helmet';
import '../styles/login.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

import { ref , uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { setDoc, doc } from 'firebase/firestore';

import { auth } from '../firebase.config';
import { storage } from '../firebase.config';
import { db } from '../firebase.config';

import { toast } from 'react-toastify';



const Signup = () => {

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const signup = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = await userCredential.user;

      const storageRef = ref(storage , `images/${Date.now() + username}`);
      const uploadTast = uploadBytesResumable(storageRef, file)

      uploadTast.on(
        (error) => {
        console.log(error.message);
      }, 
      () => {
        getDownloadURL(uploadTast.snapshot.ref).then(async(downloadURL)=>{

          //update user profile
          await updateProfile(user, {
            displayName: username,
            photoURL: downloadURL,
          });

          //store user data in firestore database

          await setDoc(doc(db, "users", user.uid), {
            uid: user.uid,
            displayName: username,
            email,
            photoUrl: downloadURL,
          })

        });
      }
      );

      setLoading(false)
      toast.success("Creation de compter reussit")
      navigate('/login')


    } catch (error) {
      setLoading(false)
      toast.error('something went wrong');
    }
  };

  return <Helmet title="Signup">
    <section>
      <Container>
        <Row>
          {
            loading? <Col lg='12' 
            className='text-center m-4'>
              <h5 className='fw-bold'>Loading.....</h5></Col>
                               : 
              <Col lg='6' className='m-auto text-center m-4'>
              <h3 className='fw-bold mb-4'>Inscription</h3>
  
              <Form className="auth__form" onSubmit={signup}>
                <FormGroup className="form__group">
                  <input type="text" placeholder='Entrer votre nom'
                    value={username} onChange={e => setUsername(e.target.value)} />
                </FormGroup>
  
                <FormGroup className="form__group">
                  <input type="email" placeholder='Entrer votre email'
                    value={email} onChange={e => setEmail(e.target.value)} />
                </FormGroup>
  
                <FormGroup className="form__group">
                  <input type="password" placeholder='Entrer votre mot de passe'
                    value={password} onChange={e => setPassword(e.target.value)} />
                </FormGroup>
  
                <FormGroup className="form__group">
                  <input
                   type="file" 
                    onChange={(e) => setFile(e.target.files[0])} />
                </FormGroup>
  
                <button type='submit' className="buy__b auth__btn">Creer un compte</button>
                <p className='text-white' >Vous avez déja un compte? {" "}
                  <Link to='/login'>se Connecter</Link></p>
              </Form>
  
            </Col>
          }
        </Row>
      </Container>
    </section>

  </Helmet>
}

export default Signup
