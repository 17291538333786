import React, { useState, useEffect } from 'react';


import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';


const AllService = () => {

  const { data: products, loading  } = useGetsData('service')

  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    setProductsData(products);
  }, [products]);


  //==== firebase firstore realtime data uptate

  const deleteProduct = async(id) => {
    await deleteDoc(doc(db, 'service', id))
    toast.success('Suppression effectuer !')
  }

 
  return (
  <Container>
    <Row>
      <Col lg='12'>
   <div className="table-responsive">
      <table  className='table bordered'>
      <thead>
      <tr>
        <th>Image</th>
        <th>Titre</th>
        <th>description</th>
       
        </tr>
      </thead>
      <tbody>
      { loading ? (<h3 className='py-5 fw-bold'>loading....</h3>) :( productsData.map(item => (  
              <tr key={item.id}>
              <td><img src={item.imgUrl} alt="" className="allimage"  /> </td>
              <td>{item.nomService} </td>
              <td>{item.Description} </td>
              <td>
              <button onClick={() => {deleteProduct(item.id)}} className="btn btn-danger">Delete</button>
              </td>
            </tr>
            )))
      }
      </tbody>
      </table>
        </div>
      </Col>
    </Row>
  </Container>
  )
}

export default AllService
