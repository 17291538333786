import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import '../styles/dashboard.css';

const AllProducts = () => {
  const { data: products, loading } = useGetsData('products');
  const [productsData, setProductsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setProductsData(products);
  }, [products]);

  useEffect(() => {
    const sortedProducts = [...products].sort((a, b) => a.Date - b.Date); // Trie les produits par date croissante
    setProductsData(sortedProducts);
  }, [products]);

  useEffect(() => {
    const searchedProducts = products.filter((item) =>
      item.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setProductsData(searchedProducts);
  }, [products, searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const deleteProduct = async (id) => {
    await deleteDoc(doc(db, 'products', id));
    toast.success('Suppression effectuée !');
  };

  return (
    <>
      <section>
        <div className='dash p-5 '>
          <div className="products__box m-2 ">
            <h5>Nombres Totals</h5>
            <span>{products.length}</span>
          </div>
        </div>
      </section>

      <Container>
        <div className='search text-center mb-2'>
          <div className="search__box">
            <input
              type="text"
              placeholder="Rechercher..."
              onChange={handleSearch}
            />
            <span>
              <i className="ri-search-line"></i>
            </span>
          </div>
        </div>
        <Row>
          <Col lg='12'>
            <div className="table-responsive mt-2">
              <table className='table bordered'>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Titre</th>
                    <th>Categorie</th>
                    <th>Arrivage</th>
                    <th>Gros</th>
                    <th>Prix</th>
                    <th>Ancien_Prix</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (<h3 className='py-5 fw-bold'>loading....</h3>) : (productsData.map(item => (
                    <tr key={item.id}>
                      <td><img src={item.imgUrl} alt="" className="allimage" /> </td>
                      <td>{item.productName} </td>
                      <td>{item.category} </td>
                      <td>{item.arrivage} </td>
                      <td>{item.gros} </td>
                      <td>{item.price} CFA </td>
                      <td>{item.aprice} CFA</td>
                      <td>
                        <button onClick={() => { deleteProduct(item.id) }} className="btn btn-danger">Delete</button>
                      </td>
                    </tr>
                  )))
                  }
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AllProducts;
