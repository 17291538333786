import React, { useRef } from 'react'
import { Container, Row } from 'reactstrap';

import useAuth from '../custom-hooks/useAuth';
import '../styles/admin-nav.css';
import { Link } from 'react-router-dom';

import { VscAccount } from 'react-icons/vsc'

import { NavLink } from 'react-router-dom';


const admin__nav = [
  {
    display: 'Listes des Message',
    path: '/dashboard'
  },

  {
    display: 'Ajouter un Produit',
    path: '/dashboard/add-product'
  },
  {
    display: 'Listes des Services',
    path: '/dashboard/all-service'
  },
  {
    display: 'Ajouter un Service',
    path: '/dashboard/add-service'
  },
 


]





const AdminNavs = () => {

  const headerRef = useRef(null);

  const menuRef = useRef(null);

  const { currentUser } = useAuth()

  const menuToggle = () => menuRef.current.classList.toggle('active__menu');

  return (
    <>
      <header ref={headerRef} className='admin__header'>
       
          <Container>
            <div className="admin__nav-warpper-top">
              <div className="logo">
                <h2>Tableau  de Bord</h2>
              </div>

              <div className="util pt-2">
              <Link to='dashboard/users' >  <a><VscAccount className='' /></a> </Link>
              </div>
              
            </div>
          </Container>
        
      </header>

      <section className="admin__menu p-0 ">
        <Container>
          <Row>
            <div className="admin__navigation navigation" ref={menuRef} onClick={menuToggle}>
              <ul className="admin__menu-list menu">
                {
                  admin__nav.map((item, index) => (
                    <li className="admin__menu-item" key={index}>
                      <NavLink to={item.path} className={navclass =>
                        navclass.isActive ? 'active__admin-menu' : ''}>{item.display} </NavLink>
                    </li>
                  ))
                }
              </ul>
            </div>

            <div className="nav__icons">

              <div className="mobile__menu">
                <span onClick={menuToggle}>
                  <i class="ri-menu-line"></i></span>
              </div>
            </div>


          </Row>
        </Container>
      </section>
    </>
  )

}

export default AdminNavs
