import React, { useState, useEffect } from 'react';
import '../styles/shop.css';

import CommoSection from '../components/UI/CommoSection';
import Helmet from '../components/Helmet/Helmet';
import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { useNavigate } from 'react-router-dom';
import ProductsListgros from '../components/UI/ProductsListgros';

const ProduitGros = () => {
  const { data: products, loading } = useGetsData('products');
  const [productsData, setProductsData] = useState(products);

  const navigate = useNavigate();

  
  useEffect(() => {
    const filtregrosProducts = products.filter(
      (item) => item.gros
      );

      setProductsData(filtregrosProducts);
  }, [products]);

  
  const handleFilter = (e) => {
    const filterValue = e.target.value;
    if (filterValue === '') {
      const filtregrosProducts = products.filter(
        (item) => item.gros
        );
  
        setProductsData(filtregrosProducts);
    } else {
      const filteredProducts = products.filter((item) => item.gros === filterValue);
      setProductsData(filteredProducts);
    }
  };


  return (
    <Helmet title="Shop">
      <CommoSection title="Listes des Produits en Gros" />
      <section >
        <Container>
          <Row>
            <Col lg='5' md='12'>
              <div className="filter__widget mt-5">
                <select onChange={handleFilter}>
                  <option value="">Tous les produits</option>
                  <option value="t-shirt">T-Shirt</option>
                  <option value="pulls">Pulls</option>
                  <option value="chemise">Chemise</option>
                  <option value="short">Short</option>
                  <option value="ensemble">Ensemble</option>
                  <option value="pantalon">Pantalon</option>
                  <option value="sacs">Sacs</option>
                  <option value="casquette">Casquette</option>
                  <option value="chaussure">Chaussure</option>
                  <option value="tapete">Tapete</option>
                  <option value="electronique">Electronique</option>
                </select>
              </div>
            </Col>

          </Row>
        </Container>
      </section>
      <section className="shop-products shop_data p-5">
        <div className="container-xxl">
          <div className="row">
          {
              loading ? (<h3 className='fw-bold'>Loading.....</h3>) : (
            <ProductsListgros data={productsData} />
            )}
            </div>
        </div>
      </section>
    </Helmet>
  );
};

export default ProduitGros;
