import React, { useState, useEffect } from 'react';
import '../styles/shop.css';
import ProductsList from '../components/UI/ProductsList';
import CommoSection from '../components/UI/CommoSection';
import Helmet from '../components/Helmet/Helmet';
import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { useNavigate } from 'react-router-dom';

const Shop = () => {
  const { data: products, loading } = useGetsData('products');
  const [productsData, setProductsData] = useState(products);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setProductsData(products);
  }, [products]);

  const handleFilter = (e) => {
    const filterValue = e.target.value;
    if (filterValue === '') {
      setProductsData(products);
    } else {
      const filteredProducts = products.filter((item) => item.category === filterValue);
      setProductsData(filteredProducts);
    }
  };

  useEffect(() => {
    const searchedProducts = products.filter((item) =>
      item.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setProductsData(searchedProducts);
  }, [products, searchTerm]);

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  };

 

  return (
    <Helmet title="Produit">
      <CommoSection title="Listes des Produits" />
      <section >
        <Container>
          <Row>
            <Col lg='5' md='12'>
              <div className="filter__widget mt-5">
                <select onChange={handleFilter}>
                  <option value="">Tous les produits</option>
                  <option value="t-shirt">T-Shirt</option>
                  <option value="pulls">Pulls</option>
                  <option value="chemise">Chemise</option>
                  <option value="short">Short</option>
                  <option value="ensemble">Ensemble</option>
                  <option value="pantalon">Pantalon</option>
                  <option value="sacs">Sacs</option>
                  <option value="casquette">Casquette</option>
                  <option value="chaussure">Chaussure</option>
                  <option value="tapete">Tapete</option>
                  <option value="electronique">Electronique</option>
                </select>
              </div>
            </Col>

            <Col lg="7" md="12">
              <div className="search__box mt-5">
                <input
                  type="text"
                  placeholder="Rechercher..."
                  onChange={handleSearch}
                />
                <span >
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="shop-products shop_data p-5">
        <div className="container-xxl">
          <div className="row">
          {
              loading ? (<h3 className='fw-bold'>Loading.....</h3>) : (
            <ProductsList data={productsData} />
            )}
          </div>
        </div>
      </section>
    </Helmet>
  );
};

export default Shop;
