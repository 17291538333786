import React from 'react'

import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const Users = () => {

    const { data: usersData, loading  } = useGetsData('users')

    const deleteUser = async(id) => {
        await deleteDoc(doc(db, 'users', id))
        toast.success('Suppression effectuer !')
    }

    return (
        <Container>
            <Row>
            <Col lg='12' className='mt-5'>
                <h4 >Utilisateurs</h4>
            </Col>
                <Col lg='12' className='pt-5'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>nom</th>
                                <th>Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                           {
                            loading ? <h5 className='pt-5 fw-bold'>Loading</h5>
                            :usersData ?.map(user=>(
                                <tr key={user.uid}>
                                <td><img src={user.photoUrl} alt="" className="allimage" /> </td>
                                <td>{user.displayName} </td>
                                <td>{user.email} </td>
                                <td>
                                    <button  onClick={() => {deleteUser(user.uid)}} className="btn btn-danger">Delete</button>{" "}
                                </td>
                            </tr>
                            ))
                           }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
    )
}

export default Users
