import React, { useRef, useEffect, useState } from 'react'
import './header.css'
import useAuth from '../../custom-hooks/useAuth';

import { motion } from "framer-motion"

import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import logo from '../../assets/images/eco-logo2.png'

import { Container, Row, Col, Form } from 'reactstrap';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '../../firebase.config';
import { toast } from 'react-toastify';

import { BiPhoneCall } from 'react-icons/bi'
import { AiOutlineMail } from 'react-icons/ai'

import { VscAccount } from 'react-icons/vsc'
import { CgShoppingCart } from 'react-icons/cg'

import useGetsData from '../../custom-hooks/useGetsData';



const Header = () => {

  const headerRef = useRef(null);

  const location = useLocation();

  const totalQuantity = useSelector(state => state.cart.totalQuantity)

  const menuRef = useRef(null);

  const navigate = useNavigate()

  const { currentUser } = useAuth()


  const { data: products } = useGetsData('products');

  const [searchTerm, setSearchTerm] = useState('');

  const stickyHeaderFunc = () => {
    window.addEventListener('scroll', () => {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop
        > 80) {
        headerRef.current.classList.add('sticky__header')
      } else {
        headerRef.current.classList.remove('sticky__header')
      }
    })
  }


  const logout = () => {

    signOut(auth).then(() => {
      toast.success('Logged out')
      navigate('/home')
    }).catch(err => {
      toast.error(err.message)
    })
  }

  useEffect(() => {
    stickyHeaderFunc()

    return () => window.removeEventListener("scroll", stickyHeaderFunc)
  });

  const menuToggle = () => menuRef.current.classList.toggle('active__menu');


  const handleSearch = e => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const handleSearchSubmit = () => {
    const searchedProducts = products.filter(item =>
      item.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    navigate(`/search?query=${searchTerm}`, { state: { productsData: searchedProducts } });
  }

  const handleLinkhome = () => {
    // Recharge la page lorsque le lien est cliqué
    window.location.href = '/home' ;
};

const handleLinkshop = () => {
  // Recharge la page lorsque le lien est cliqué
  window.location.href = '/shop' ;
};

const handleLinkservice = () => {
  // Recharge la page lorsque le lien est cliqué
  window.location.href = '/service' ;
};

  return <header className='header' ref={headerRef}>


    <Container>
      <Row>

        <div className="head container-xxl ">
          <div className="row ">
            <div className='d-flex align-items-center   justify-content-between '>
              <div className=" d-flex liv">
                <a className='text-white'> Livraison à domicile disponible</a>
              </div>

              
              <a href="tel:+228 92170365" className='contact'> <BiPhoneCall />: +228 92170365</a>


              <div className=' d-flex  justify-content-between'>

              </div>
            </div>
          </div>

        </div>

        <div className="nav__wrapper">
        <Link to={`/home`}> 
          <div className="logo"> 
            <img src={logo} alt="logo" />
            <div className='logot'>
              <h1>Zombuy Shop</h1>
            </div>
          </div> 
          </Link>

          <Col  >

            <div className="d-flex align-items-center search ">
              <div class="input-group mb-3">

                <input type="text" class="input"
                  placeholder="Search produits"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <button class="btn" id="basic-addon2" onClick={handleSearchSubmit}>Search</button>

              </div>
            </div>

          </Col>
          <div className="navigation   " ref={menuRef} onClick={menuToggle}>
            <ul className="menu  nav__item nav-links d-flex  align-items-center  ">

              <Link  onClick={handleLinkhome}  className={location.pathname === '/home' ? 'active' : 'inactive'} >ACCUEIL</Link>
              <Link  onClick={handleLinkshop}  className={location.pathname === '/shop' ? 'active' : 'inactive'}>PRODUITS</Link>
              <Link  onClick={handleLinkservice}  className={location.pathname === '/service' ? 'active' : 'inactive'}>NOS SERVICES</Link>

            </ul>
          </div>
          <div className="nav__icons">

            <div className="mobile__menu">
              <span onClick={menuToggle}>
                <i class="ri-menu-line"></i></span>
            </div>
          </div>


        </div>
      </Row>
    </Container>
  </header>


};

export default Header
